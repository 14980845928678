import { ClaimSessionStatusToText } from '@helpers';
import { isEqual } from 'lodash';
import { useRouter } from 'nuxt/app';

import { useOrdersStore, useClaimStore } from '~/store';

export default defineNuxtPlugin(() => {
  const router = useRouter();
  const orderStore = useOrdersStore();
  const claimStore = useClaimStore();
  const DEFAULT_CLAIM = {
    customer: {},
    fulfillments: {},
    resolution: {
      desiredMethod: null,
      updatedShippingAddress: null,
    },
    signature: null,
  };

  router.beforeEach(async to => {
    if (!orderStore.currentOrder?.systemOrderId) return;

    const status = Object.entries(ClaimSessionStatusToText).find(([, path]) => to.path.endsWith(path))?.[0];

    if (status && claimStore.claimSession.status !== status) {
      await claimStore.updateClaimSession({
        email: orderStore.currentOrder.customerEmail,
        orderId: orderStore.currentOrder.systemOrderId,
        status,
      });
    }
  });

  claimStore.$subscribe(async (mutation, state) => {
    if (!claimStore.claimSession) return;

    const usUpdatingClaimState = mutation.events?.key === 'value' && !!mutation.events?.target?.value?.customer;

    const usUpdatingCurrentShipmentId =
      mutation.events?.key === 'value' &&
      !mutation.events?.target?.value?.customer &&
      isEqual(claimStore.claimSession.metadata?.currentShipmentId, mutation.events?.newValue);

    const noFulfillmentWithCurrentShipmentId = !state.claim?.fulfillments?.[state.currentShipmentId];

    if (
      usUpdatingClaimState ||
      usUpdatingCurrentShipmentId ||
      noFulfillmentWithCurrentShipmentId ||
      (isEqual(state.claim, DEFAULT_CLAIM) && !state.currentShipmentId)
    )
      return;

    const updateData = {
      email: orderStore.currentOrder.customerEmail,
      metadata: state,
      orderId: orderStore.currentOrder.systemOrderId,
    };

    return claimStore.updateClaimSession(updateData);
  });
});
