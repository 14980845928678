import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import { applyFontFace, applyTheme } from '~/helpers';
import { WhitelabelService } from '~/support/services';

export const useWhiteLabelStore = defineStore('whitelabel', () => {
  const config = useRuntimeConfig();
  const service = new WhitelabelService(config.public.apiBaseUrl);
  const branding = ref(null);
  const applied = ref(false);

  async function find(location = window.location.hostname, storeId) {
    try {
      const settings = await service.find(location, storeId);
      branding.value = settings;
      applyTheme(settings);
      applyFontFace(settings);

      return true;
    } catch {
      return true;
    }
  }

  /**
   * GETTERS
   */

  function get(name) {
    return branding.value?.[name];
  }

  const hasCustomLogo = computed(() => !!get('logoUrl'));

  return {
    applied,
    branding,
    find,
    get,
    hasCustomLogo,
  };
});
