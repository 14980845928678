import { shallowMount } from '@vue/test-utils';
import { describe, test, expect, beforeEach } from 'vitest';

import Component from './MediaUploader.vue';

let wrapper;
let vm;

describe('MediaUploader', () => {
  beforeEach(() => {
    wrapper = shallowMount(Component, {
      props: { label: 'Media' },
    });

    vm = wrapper.vm;
  });

  describe('props', () => {
    test('required', async () => {
      expect(wrapper.html()).toMatchSnapshot();

      await wrapper.setProps({ required: true });
      expect(wrapper.html()).toMatchSnapshot();
    });

    test('max', async () => {
      await wrapper.setProps({ max: 3 });
      expect(wrapper.vm.max).toBe(3);
    });

    test('multiple', async () => {
      await wrapper.setProps({ multiple: true });
      expect(wrapper.vm.multiple).toBe(true);
    });
  });

  describe('computed', () => {
    describe('displayMedia', () => {
      test('empty', () => {
        expect(vm.displayMedia).toEqual([]);
      });

      test('string URLs', async () => {
        wrapper = shallowMount(Component, {
          props: { media: ['https://example.com/image.jpg'] },
        });
        vm = wrapper.vm;

        expect(vm.displayMedia).toHaveLength(1);
        expect(vm.displayMedia[0].type).toBe('image');
        expect(vm.displayMedia[0].url).toBe('https://example.com/image.jpg');
      });

      test('file objects', async () => {
        const fileMock = new File(['(file content)'], 'test-file.jpg', {
          type: 'image/jpeg',
        });

        wrapper = shallowMount(Component, {
          props: { media: [fileMock] },
        });
        vm = wrapper.vm;

        expect(vm.displayMedia).toHaveLength(1);
        expect(vm.displayMedia[0].type).toBe('image');
        expect(vm.displayMedia[0].url).toContain('blob:'); // Blob URL for file objects
      });

      test('video files', async () => {
        const videoMock = new File(['(video content)'], 'test-video.mp4', {
          type: 'video/mp4',
        });

        wrapper = shallowMount(Component, {
          props: { media: [videoMock] },
        });
        vm = wrapper.vm;

        expect(vm.displayMedia).toHaveLength(1);
        expect(vm.displayMedia[0].type).toBe('video');
        expect(vm.displayMedia[0].url).toContain('blob:'); // Blob URL for file objects
      });
    });
  });

  describe('methods', () => {
    test('removeMedia', async () => {
      const fileMock = new File(['(file content)'], 'test-file.jpg', {
        type: 'image/jpeg',
      });

      wrapper = shallowMount(Component, {
        props: { media: [fileMock] },
      });
      vm = wrapper.vm;

      vm.removeMedia(0);
      expect(vm.files).toHaveLength(0);
    });
  });
});
