import { formatDate } from '@helpers';
import { filter, map } from 'lodash';
import get from 'lodash/get';
import { defineStore } from 'pinia';
import short from 'short-uuid';
import { computed, ref, reactive } from 'vue';

import useFileUploader from '~/composables/useFileUploader';
import { DISPLAY_ORDER_RESOLUTIONS } from '~/support/constants';
import { ClaimService } from '~/support/services';

import { isClaimOrderLevel, isClaimItemLevel } from '../../../../libs/shared/helpers/claims.helper';

export const DEFAULT_CLAIM = {
  customer: {},
  fulfillments: {},
  resolution: {
    desiredMethod: null,
    updatedShippingAddress: null,
  },
  signature: null,
};

export const useClaimStore = defineStore('claim', () => {
  const state = reactive({
    claimSession: null,
  });
  // Had to change claim to a ref so that the subscription in claim-session.js plugin can detect changes with reactive it wasn't picking up every change
  const claim = ref(DEFAULT_CLAIM);
  const currentShipmentId = ref(null);
  const config = useRuntimeConfig();
  const service = new ClaimService(config.public.apiBaseUrl);
  const { uploadFiles } = useFileUploader();

  async function findClaimById(id) {
    return service.findClaimById(id);
  }

  async function findClaimSession(orderId) {
    const session = await service.findClaimSession(orderId);
    state.claimSession = session;

    if (session.metadata?.claim) {
      claim.value = session.metadata.claim;
    }

    if (session.metadata?.currentShipmentId) {
      currentShipmentId.value = session.metadata.currentShipmentId;
    }

    return session;
  }

  async function updateClaimSession(data) {
    const updatedSession = await service.updateClaimSession(data);
    state.claimSession = updatedSession;

    if (updatedSession.metadata?.claim && data.metadata?.claim) {
      claim.value = data.metadata.claim;
    }

    if (updatedSession.metadata?.currentShipmentId && data.metadata?.currentShipmentId) {
      currentShipmentId.value = data.metadata.currentShipmentId;
    }

    return updatedSession;
  }

  async function deleteClaimSession() {
    if (!state.claimSession) return;

    await service.deleteClaimSession(state.claimSession.id);
    state.claimSession = null;
    claim.value = DEFAULT_CLAIM;
    currentShipmentId.value = null;
  }

  async function sendClaimMessage(content, sourceOrderId, customerId) {
    let medias = [];

    if (content.files.length > 0) {
      medias = await uploadFiles(content.files);
    }

    const claimMessageInput = {
      claimId: claim.value.id,
      medias,
      messageContent: content.messageContent,
      senderId: customerId,
      senderName: claim.value.customer,
      senderType: 'customer',
      storeId: claim.value.store,
    };

    await createClaimMessage(claimMessageInput, claim.value.store, sourceOrderId);

    const newMessages = await loadClaimMessages(claim.value.id, sourceOrderId);

    claim.value.messages = newMessages;
  }

  async function createClaimMessage(data, storeId, sourceOrderId) {
    return service.createClaimMessage(data, storeId, sourceOrderId);
  }

  async function loadClaimMessages(claimId, sourceOrderId) {
    return service.loadClaimMessages(claimId, sourceOrderId);
  }

  function addFulfillmentIssues(id, issues) {
    const issuesWithIds = issues.map(issue => ({ ...issue, id: short.generate() }));

    if (!claim.value.fulfillments[id]) {
      claim.value.fulfillments[id] = {
        id: id,
        issues: issuesWithIds,
      };
    } else {
      /**
       * Remove the issues for the sourceItemId that are already in the store.
       */
      claim.value.fulfillments[id].issues = filter(
        claim.value.fulfillments[id].issues,
        ({ sourceItemId }) => !map(issues, 'sourceItemId').includes(sourceItemId),
      );

      /**
       * Add the new issues for that sourceItemId to the store.
       */
      claim.value.fulfillments[id].issues = claim.value.fulfillments[id].issues.concat(issuesWithIds);
    }
  }

  function setClaim(claimData) {
    claim.value = claimData;
  }

  /**
   * @param {Array.<string>} fulfillments
   * sets the fulfillment object in the store
   * this allows for the routing object to loop through the fulfillments with issues
   *
   * @returns {void}
   */
  function setFulfillmentState(fulfillments) {
    fulfillments.forEach(id => {
      claim.value.fulfillments[id] = {
        id,
        issues: [],
      };
    });
  }

  /**
   * @param {string} id
   * sets the current shipment id, this is used to keep track of the current shipment issues.
   *
   * @returns {void}
   */
  function setCurrentShipmentId(id) {
    currentShipmentId.value = id;
  }

  /**
   * Sets the desired resolution for the claim.
   * @enum {string} resolution
   */
  function setRequestedResolution(resolution) {
    claim.value.resolution.desiredMethod = resolution;
  }

  /**
   * setShippingAddress
   * updates the claim.resolution.updatedShippingAddress with the provided address
   * @param {Object} address
   */
  function setShippingAddress(address) {
    claim.value.resolution.updatedShippingAddress = address;
  }

  /**
   * Sets the whole shipment issue. This allows us to persist data between the shipment type and the details
   * We have to set details as well so that we can loop through and add each item with the same issue
   * @param {string} id
   * @param {string} type
   */
  function setShipmentIssueType(id, type) {
    claim.value.fulfillments[id].shipmentIssue = type;
  }

  /**
   * Sets the details for a shipment issue
   * this is global and is separate from the issues. This is just for data persistence
   * @param {string} id
   * @param {string} details
   */
  function setShipmentIssueDetails(id, details) {
    claim.value.fulfillments[id].details = details;
  }

  function addSignature(data) {
    claim.value.signature = data;
  }

  function deleteIssueByFulfillmentAndIssue(fulfillmentId, issueId) {
    claim.value.fulfillments[fulfillmentId].issues = claim.value.fulfillments[fulfillmentId].issues.filter(
      ({ id }) => id !== issueId,
    );
  }

  function getIssuesByFulfillmentAndProduct(fulfillmentId, sourceItemId) {
    return get(claim.value.fulfillments, [fulfillmentId, 'issues'], []).filter(
      ({ sourceItemId: id }) => id === sourceItemId,
    );
  }

  function submitClaim() {
    return service.createClaim({
      claim: claim.value,
      ...claim.value.customer,
    });
  }

  function setCustomer({ email, sourceOrderId }) {
    const customer = {
      email,
      sourceOrderId,
    };

    claim.value = { ...DEFAULT_CLAIM, ...claim.value, customer };
  }

  function reset() {
    claim.value.fulfillments = {};
    claim.value.resolution.updatedShippingAddress = null;
    claim.value.signature = null;
  }

  /**
   * @typedef {Object} FoundationFulfillment
   * @property {string} id
   * @property {Array.<Object>} issues
   *
   * @returns {Array.<FoundationFulfillment>}
   */
  const unfinishedFulfillments = computed(() => {
    return Object.values(claim.value.fulfillments).filter(({ issues }) => issues.length === 0);
  });

  const isOrderLevel = computed(() => {
    return isClaimOrderLevel(claim.value);
  });

  const isItemLevel = computed(() => {
    return isClaimItemLevel(claim.value);
  });

  const messages = computed(() => {
    if (!claim.value.messages) {
      return [];
    }

    return claim.value.messages.map(message => {
      return {
        ...message,
        date: formatDate(message.createdAt),
        // image: 'https://picsum.photos/200',
        key: message.id,
        medias: message.medias || [],
        message: message.messageContent,
        senderType: message.senderType,
        type: 'message',
        username: message.senderName,
      };
    });
  });

  const resolution = computed(() => {
    return claim.value.resolution ? claim.value.resolution : null;
  });

  const currentShipment = computed(() => {
    return claim.value.fulfillments[currentShipmentId.value];
  });

  const currentShipmentIssues = computed(() => {
    return currentShipment.value?.issues || [];
  });

  const allIssues = computed(() => {
    return Object.values(claim.value.fulfillments).reduce((all, { issues }) => all.concat(issues), []);
  });

  const requestedResolution = computed(() => DISPLAY_ORDER_RESOLUTIONS[claim.value.resolution.desiredMethod]);

  const claimSession = computed(() => state.claimSession);

  return {
    addFulfillmentIssues,
    addSignature,
    allIssues,
    claim,
    claimSession,
    createClaimMessage,
    currentShipment,
    currentShipmentId,
    currentShipmentIssues,
    deleteClaimSession,
    deleteIssueByFulfillmentAndIssue,
    findClaimById,
    findClaimSession,
    getIssuesByFulfillmentAndProduct,
    isItemLevel,
    isOrderLevel,
    loadClaimMessages,
    messages,
    requestedResolution,
    reset,
    resolution,
    sendClaimMessage,
    setClaim,
    setCurrentShipmentId,
    setCustomer,
    setFulfillmentState,
    setRequestedResolution,
    setShipmentIssueDetails,
    setShipmentIssueType,
    setShippingAddress,
    submitClaim,
    unfinishedFulfillments,
    updateClaimSession,
  };
});
