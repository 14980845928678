import { shallowMount } from '@vue/test-utils';
import { createPinia, setActivePinia } from 'pinia';

import Component from './Card.vue';

let wrapper;

describe('Card', () => {
  beforeEach(() => {
    setActivePinia(createPinia());

    wrapper = shallowMount(Component);
  });

  test('render', () => {
    expect(wrapper.isVisible()).toBe(true);
  });
});
