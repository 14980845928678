function normalizeKeys(obj) {
  const keyMap = {
    backgroundColor: 'background-color',
    backgroundImage: 'background-image',
    brand100: 'primary-100',
    brand700: 'primary-700',
    brand800: 'primary-800',
    brand900: 'primary-900',
    desktopHeadlineFontSize: 'desktop-headline-font-size',
    desktopHeadlineLetterSpacing: 'desktop-headline-letter-spacing',
    desktopHeadlineLineHeight: 'desktop-headline-line-height',
    headlineTextTransform: 'headline-text-transform',
    logoWidthDesktop: 'logo-width-desktop',
    logoWidthMobile: 'logo-width-mobile',
    mobileHeadlineFontSize: 'mobile-headline-font-size',
    mobileHeadlineLetterSpacing: 'mobile-headline-letter-spacing',
    mobileHeadlineLineHeight: 'mobile-headline-line-height',
  };

  return Object.keys(obj).reduce((keys, key) => {
    if (!keyMap[key] && key !== 'buttonStyle') {
      return keys;
    }

    const normalizedKey = keyMap[key];

    if (key === 'buttonStyle') {
      const radius = {
        ROUNDED: '6px',
        ROUNDED_FULL: '9999px',
        SQUARE: '0px',
      };

      const style = obj[key];
      keys['button-style'] = radius[style];

      return keys;
    }

    if (/(url|image)/.test(normalizedKey)) {
      keys[normalizedKey] = `url(${obj[key]})`;

      return keys;
    }

    keys[normalizedKey] = obj[key];

    return keys;
  }, {});
}

export function applyTheme(theme) {
  const root = document.documentElement;
  Object.entries(normalizeKeys(theme)).forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });
}

export function applyFontFace({ fontWoff, fontWoff2, family }) {
  if (!fontWoff && !fontWoff2) {
    console.warn('No font files provided!');

    return;
  }

  // Generate a temporary unique font-family name since we don't have a family name from woff urls
  const uniqueFamily = family || `CustomFont-${Date.now()}`;

  // Ensure we only include the available formats
  const fontSources = [];
  if (fontWoff2) fontSources.push(`url('${fontWoff2}') format('woff2')`);
  if (fontWoff) fontSources.push(`url('${fontWoff}') format('woff')`);

  const style = `
  @font-face {
    font-family: '${uniqueFamily}';
    src: ${fontSources.join(', ')};
    font-weight: normal;
    font-style: normal;
  }
  `;

  // Inject the font-face into the document
  const styleTag = document.createElement('style');
  styleTag.innerHTML = style;
  document.head.appendChild(styleTag);

  // Apply the font to --headline-font in CSS
  document.documentElement.style.setProperty('--headline-font', uniqueFamily);
}
